import { getElementsToArray } from "../../helpers/getElementsToArray";
import { CompositeMenu } from "../../components/SubView/CompositeMenu";
import { Menu } from "../../components/SubView/Menu"; 
import { Modal } from "../../components/SubView/Modal";

export function initializeComponents(components)
{
    components.modal = typeof components.modal !== "undefined" ? new Modal() : null; // because we only want these on the clients page

    // data-menu=0 is the root of our composite / mobile menu.
    // We're only building one menu here,
    // but we could easily generalize this
    // to automatically build any amount of composite menus
    // by differentiating the start of a composite menu
    // (e.g., [data-composite-menu]) and looping through those as well.
    components.mobileMenu = new CompositeMenu(); 
    
    var menus = getElementsToArray('[data-menu]'); 
    var menuBtns = getElementsToArray('[data-menu-toggle]');
    var elsWithStateSyncedToMenu = null; 
    var focusables = null; 

    for (var i = 0; i < menus.length; i++)
    {
        // basically, the toggle btn for this 
        // menu, the direct links inside menu,
        // and any buttons that toggle submenus.
        // What is not included here are all of the
        // above inside this menu's potential submenu(s).
        // Each submenu will have its own set of the above.
        focusables = [menuBtns[i]].concat( 
            getElementsToArray('[data-menu="' + i + '"] > li > a[href], [data-menu="' + i + '"] > li > button')
        );

        elsWithStateSyncedToMenu = getElementsToArray('[data-sync-state-with-menu="' + i + '"]'); 

        components.mobileMenu.add( new Menu(menus[i], menuBtns[i], focusables, elsWithStateSyncedToMenu) );
    }
}