export function keepFocusInOpenComponent(components)
{
    var lastOpenComponent;

    function decideFocus(e)
    {
        if (lastOpenComponent && lastOpenComponent.isOpen) // just keep it open then since only one (modal, mobile menu) should be open at a time
        {
            lastOpenComponent.maintainFocus(e); 
            return; 
        }
        else if (components.mobileMenu.isOpen)
        {
            components.mobileMenu.maintainFocus(e); 
            lastOpenComponent = components.mobileMenu; 
        }
        else if (components.modal.isOpen)
        {
            components.modal.maintainFocus(e); 
            lastOpenComponent = components.modal; 
        }
    }

    // The below two eventListeners help so that focus is maintained within modal/menu when it's opened.
    // If we didn't have the keydown listener, then when the user tabbed on last focusable elem
    // within modal then they could tab outside the modal as well, which isn't
    // what the correct behavior should be. If we didn't have the focus listener the
    // user could click, for instance, on the address bar and then tab back
    // into the document to something outside the modal. 
    window.addEventListener("focus", decideFocus);
    window.addEventListener("keydown", decideFocus);
}