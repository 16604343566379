import {SubView} from "../"; 
import {add} from "./methods/add";
import {addMenuToFocusables} from "./methods/addMenuToFocusables";
import {close} from "./methods/close"; 
import {open} from "./methods/open"; 
import {removeMenuFromFocusables} from "./methods/removeMenuFromFocusables"; 
import {toggle} from "./methods/toggle"; 
import {updateFocusables} from "./methods/updateFocusables"; 


// W3C's Web Accessibility Initiative (WAI) 
// lists best practices for building two 
// different types of accessible menus in web apps: 
// https://www.w3.org/WAI/tutorials/menus/
// Our mobile menu is a type of fly-down menu, which 
// according to WIA should have different
// behaviors than an application menu. 
// The hard part here is how to implement 
// focus behavior within the menu, which 
// WIA isn't too opinionated on (except that
// tabbing shouldn't open a submenu from 
// the parent). Different sites go about 
// this in various different ways. 
// After discussions with SC and their site's users, we've decided
// to take the composite menu approach: 
// focus is implemented like with an application menu, but 
// with only the tab key being used to navigate the
// menu by keyboard (e.g., tab selects
// the next item in the menu, if at last item then
// it selects the first item and vice versa. Shift+tab
// works the opposite way). Furthermore, more than
// one submenu can be opened at once. Thus the composite's
// list of focusable elements is kept updated. 
// Finally, hitting the Escape key closes the entire
// composite menu. 
// An alternative approach that was discussed and
// rejected was with focus only being maintained within
// one open menu at a time (the tab behavior 
// described above applying to the submenu as if
// it were the only open menu), and once a submenu is opened,
// the others (should only be one other) are closed. 
// Esc closes the submenu (or enter on the toggle btn)
// and hitting Esc again closes the whole menu. 
export function CompositeMenu()
{
    // Our CompositeMenu is based on the composite design pattern,
    // where both the leaf and composite classes implement the same
    // interface (here they extend the same class) and the composite class
    // forwards all ops to the leaves. 
    // Our CompositeMenu class was adapted from a previously used
    // CompositeModal class, which was more in line with a strict 
    // implementation of the pattern. Here, for instance, 
    // we no longer open all menus at once (idea was discussed and
    // discarded), but we still close them all at once 
    // (but not all the time). This lets us
    // treat the composite menu like one synced menu, but also still
    // lets each menu act independently of the whole (but we still 
    // forward all ops to the actual children). 
    // This presents a problem for the focusables, since they'll 
    // now change. Thus we can view the result of the ops we forward
    // to the children as new focusables to add or remove 
    // from our list. Our updateFocusables takes care of this
    // 'summation' of focusables for us (called each time 
    // a menu opens or closes).
    SubView.call(this);
    this.menus = [];
}

CompositeMenu.prototype = Object.create(SubView.prototype, {
    constructor: {
        value: CompositeMenu,
        enumerable: false,
        writable: true,
        configurable: true,
    },
});

CompositeMenu.prototype.add = add; 
CompositeMenu.prototype.addMenuToFocusables = addMenuToFocusables;
CompositeMenu.prototype.close = close; 
CompositeMenu.prototype.open = open; 
CompositeMenu.prototype.removeMenuFromFocusables = removeMenuFromFocusables; 
CompositeMenu.prototype.toggle = toggle; 
CompositeMenu.prototype.updateFocusables = updateFocusables; 