export function updateFocusables(menuId)
{
    if (menuId || menuId === 0) 
    {
        if (this.menus[menuId].isOpen) 
        {
            this.addMenuToFocusables(menuId); 
        }
        else // then menu was closed
        {
            this.removeMenuFromFocusables(menuId); 
        }
    }
    else // opened or closed all menus
    {
        this.focusables = []; 

        this.menus.forEach(function(menu, i)
        {
            if (menu.isOpen)
            {
                this.addMenuToFocusables(i); 
            }
        });
    }
}