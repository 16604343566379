// abstract methods that we want our SubViews to implement
// SubView.prototype.open = function(e)
// {
//    
// };

// SubView.prototype.close = function(e)
// {
//     
// };

import {maintainFocus} from "./methods/maintainFocus";
import { getElementsToArray } from "../../helpers/getElementsToArray";

export function SubView(focusables)
{
    // focusables: list of elements that can have focus
    this.focusables = typeof focusables === 'string' 
        ? 
            getElementsToArray(focusables) 
        :
            focusables ? focusables : []; 

    this.isOpen = false; 
}

SubView.prototype.maintainFocus = maintainFocus; 

