import { fixHeader } from "../../helpers/fixHeader"; 
import { fixBackgrounds } from "../../helpers/fixBackgrounds";

export function fixElementsOnScrollAndResize(elementsToFix)
{
    function fixElements()
    {
        fixHeader(elementsToFix[0]); // 0 is always the header
        elementsToFix[1] && fixBackgrounds(elementsToFix[1]); // and 1 will always contain array of backgrounds, but only on clients page
    }

    fixElements(); // for dcl
    window.addEventListener('scroll', fixElements); 
    window.addEventListener('resize', fixElements); 
}
