export function close() 
{
    this.root.classList.remove('open');
    this.toggleBtn.classList.remove('open');
    this.toggleBtn.setAttribute("aria-expanded", "false");
    
    this.isOpen = false; 

    this.elemsWithStateSyncedToMenu.forEach(function(elem)
    {
        elem.classList.remove("open"); 
    });
}