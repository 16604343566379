export function open(menuId)
{
    if ( menuId || menuId === 0 ) // open a menu
    {
        this.menus[menuId].open();
    }
    else // open all
    {
        this.menus.forEach(function(menu)
        {
            menu.open(); 
        });
    }

    var compositeRootIndex = 0; 

    // isOpen when 0 is open 
    // because it's the main menu 
    // that all other submenus open and close
    // within.
    if ( this.menus[compositeRootIndex].isOpen ) 
    {
        this.isOpen = true; 
    }

    this.updateFocusables(menuId); 
}