import { SubView } from "..";
import { getElement } from "../../../helpers/getElement";
import { getElementsToArray } from "../../../helpers/getElementsToArray";
import { ModalItem } from "../../ModalItem";
import { displayWithContent } from "./methods/displayWithContent"; 
import { resetContent } from "./methods/resetContent"; 
import { open } from "./methods/open";
import { close } from "./methods/close"; 

// For the main modal where main content is displayed. There's one main modal
// but the content for it is dynamically generated depending on 
// what modalBtn/img we click on. Important since we need to dynamically set
// the correct ariaLabelledBy attr value, since we won't know
// until the modal opens what labels it.
export function Modal()
{
    SubView.call(this); // extends SubView

    // 3 modal parts we'll refer to again.
    // modalContainer is container for actual modal
    // that overlays the whole viewport. This is so 
    // anytime we click outside modal (i.e., in the
    // modal container) it closes the modal. 
    // These are obviously static, but if we
    // ever had more than one modal we could pass
    // these in to the constructor as we do with
    // the menu class.
    this.container = getElement('.modal');
    this.dialog = getElement('.modal-content');  
    this.closeBtn = getElement('.close-modal');

    this.elemInFocusBeforeMenuOpen = null;
    this.activeModalItem = null; 

    // as of now, our template engine dynamically renders the html
    // for the page when it's requested--all of the client data 
    // (i.e., docs, pdfs, images, etc) is embedded in the html. 
    // This obviously won't scale. And though it will probably never
    // have to scale, if it did need to we could only build one
    // modal item (in the html, and init n empty modalItem objs
    // ), and fill its structure dynamically when each button is
    // clicked via a new request. 
    var headers = getElementsToArray('.modal-header-content');
    var bodies = getElementsToArray('.client-docs'); 
    var footers = getElementsToArray('.modal-footer-content'); 

    if ( !(headers.length === bodies.length && bodies.length === footers.length) )
    {
        console.log('SC ERROR! Please make sure each modal item has matching header, body, and footer!') 
        console.log(headers,bodies,footers); 
    }

    this.modalItems = {};

    for (var i = 0; i < headers.length; i++)
    {
        var itemId = headers[i].id; 

        this.modalItems[itemId] = new ModalItem(itemId, headers[i], bodies[i], footers[i]);
    }

};

Modal.prototype = Object.create(SubView.prototype, {
    // If we don't set Modal.prototype.constructor to Modal,
    // it will take the prototype.constructor of its parent class SubView,
    // which doesn't matter when constructing an obj with new Modal(), but 
    // it helps if we ever want to know afterwards the constructor fn that
    // made it.
    constructor: {
        value:  Modal,
        enumerable: false,
        writable: true,
        configurable: true,
    },
});

Modal.prototype.displayWithContent = displayWithContent; 
Modal.prototype.resetContent = resetContent; 
Modal.prototype.open = open;
Modal.prototype.close = close;
