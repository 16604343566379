import { addClass } from "./methods/addClass"; 
import { removeClass } from "./methods/removeClass";

export function ModalContent(header, body, footer)
{
    this.header = header;
    this.body = body;
    this.footer = footer; 
};

ModalContent.prototype.addClass = addClass; 
ModalContent.prototype.removeClass = removeClass; 