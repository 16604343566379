import {getElement} from "../../helpers/getElement";

export function resetBodyOverflowX()
{
    setTimeout(function()
    {
        var html = getElement('html'); 

        // Need this to prevent page jumping to right on load/reload, which can happen randomly on some browsers.
        // Page jumps to right (even with overflow-x: hidden) because we have absolutely positioned elements off screen to left and right that animate back to center on scroll.
        // need all three below because certain broswers will only work with a certain one in exclusion of the rest (e.g., for ie only document.body works; the rest are ignored);
        html.scrollLeft = 0; 
        document.documentElement.scrollLeft = 0; 
        document.body.scrollLeft = 0; 

    }, 100); //need to wrap in setTimeout because page will jump fraction of sec after load and after scrollLeft set to 0; 
}