export function toggle(menuId)
{
    if ( menuId || menuId === 0 )
    {

        this.menus[menuId].isOpen ? this.close(menuId) : this.open(menuId); 
    }
    else
    {
        this.menus.forEach(function(menu)
        {
            menu.isOpen ? this.close() : this.open(); 
        });
    }
}