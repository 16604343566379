import {getElementsToArray} from "../../helpers/getElementsToArray"; 
import {getElement} from "../../helpers/getElement"; 

export function autoSmoothScrollToSectionOnLinkClick()
{
    var scrollLinks = getElementsToArray('.scroll'); 

    function animateScroll({timing, draw, duration}) 
    {
        var start = null; 
        
        requestAnimationFrame(function animateScroll(time) 
        {
            start = start ? start : window.performance.now(); 

            // timeFraction goes from 0 to 1
            var timeFraction = (time - start) / duration;
            if (timeFraction > 1) timeFraction = 1;

            // calculate the current animation state. 
            // The animation timing function could be 
            // whatever you choose (e.g., for linear, just return the timeFraction).
            var progress = timing(timeFraction);

            draw(progress); // draw the animation

            if (timeFraction < 1) 
            {
                requestAnimationFrame(animateScroll);
            }

        });
    }

    function easeScroll(timeFraction)
    {
        //sinusoidal easeout
        return Math.sin( timeFraction * (Math.PI/2) );
    }

    var html = getElement('html'); // declared here so we don't query for it every frame in change fn below

    function changeScrollY(elemPosition, scrollToPosition, progress)
    {
        //need to set all 3 below because for some browsers only 1 or 2 of these values can change, while the others stay at zero. 
        html.scrollTop =  progress * elemPosition + (scrollToPosition - elemPosition);
        document.body.scrollTop = progress * elemPosition + (scrollToPosition - elemPosition);
        document.documentElement.scrollTop = progress * elemPosition + (scrollToPosition - elemPosition);
    }

    function scrollToSection(e)
    {
        e.preventDefault(); // to prevent jumping to hash;

        var elemPosition = document.querySelector(this.hash).getBoundingClientRect().top; // position relative to viewport
        var scrollToPosition = elemPosition + window.pageYOffset; // elem pos relative to document

        animateScroll( { 
            duration: 1000, 
            timing: easeScroll, 
            draw: function(progress) { changeScrollY(elemPosition, scrollToPosition, progress) }
        });
    }

    scrollLinks.forEach(function(link)
    {
        link.addEventListener('click', scrollToSection);
    });
}