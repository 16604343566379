export function maintainFocus(e)
{
    if (e.type === 'focus')
    {
        e.preventDefault();
        this.focusables[0].focus(); 
    }
    else if  (e.type === 'keydown')
    {
        if (typeof e.key === 'undefined') 
        {
            // polyfill for older browsers using Event.keyCode
            e.key = e.keyCode === 9 ? 'Tab' : e.keyCode === 27 ? 'Escape' : '';
        }

        var firstFocusable = this.focusables[0];
        var lastFocusable = this.focusables[this.focusables.length - 1];
    
        switch(e.key) 
        {
            case 'Tab':
                if ( this.focusables.length === 1 ) 
                {
                    // shouldn't ever have an empty modal 
                    // (focusables.length === 1 would be 
                    // only the close button), but if we have
                    // a client listed without docs, then keep
                    // focus on the one el no matter if tab is 
                    // pressed again. 
                    e.preventDefault(); 
                } 
                else if ( this.focusables.indexOf(document.activeElement) === -1 )
                {
                    // For instance, if you highlight the 
                    // footer text of the modal then hit tab,
                    // since the p isn't a focusabelEl, tab will
                    // tab out of the modal. This prevents that and
                    // simply sets the focus back to the modal. 
                    e.preventDefault();
                    firstFocusable.focus(); 
                }
                else if ( e.shiftKey ) // not own case because we can hit tab plus shift  
                {
                    if ( document.activeElement === firstFocusable )
                    {
                        // then we're trying to shift back from firstEl 
                        // which should take us to last focusableEl
                        e.preventDefault();
                        lastFocusable.focus();
                    }
                } 
                else if ( document.activeElement === lastFocusable )
                {
                    e.preventDefault();
                    firstFocusable.focus(); 
                }
                break;
            case 'Esc': // IE/Edge val
            case 'Escape':
                this.close(); 
                break;
            default:
                break;
        }
    }
}