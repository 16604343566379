export function close(menuId)
{
    if ( menuId || menuId === 0 )
    {
        this.menus[menuId].close(); 
    }
    else
    {
        this.menus.forEach(function(menu)
        {
            menu.close(); 
        });
    }

    var compositeRootIndex = 0; 

    if ( !this.menus[compositeRootIndex].isOpen )
    {
        this.isOpen = false; 
        this.menus[compositeRootIndex].toggleBtn.focus(); 
    }

    this.updateFocusables(menuId); 
}