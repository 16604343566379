import { getElementsToArray } from "../../helpers/getElementsToArray";

export function handleOpeningAndClosingModal(modal)
{
    var modalBtns = getElementsToArray('.column button');
//var modal = app.components.modal; 
    var openModal = modal.open.bind(modal); // bind so in open we can use 'this' for modal

    modalBtns.forEach(function(modalBtn)
    {
        modalBtn.addEventListener('click', openModal); 
    });

    // have to use this fn instead of using
    // modal.close for each event handler
    // below because the modal btn is inside
    // the container, hence both listeners will
    // run when the modalBtn is clicked. 
    function closeModal(e)
    {
        if ( !modal.isOpen )
        {
            return;
        }

        if (e.target === modal.closeBtn || e.target === modal.container)
        {
            modal.close();
        }
    }

    modal.closeBtn.addEventListener('click', closeModal); 
    modal.container.addEventListener('click', closeModal); 
};