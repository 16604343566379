import {SubView} from "../"; 
import {open} from "./methods/open"; 
import {close} from "./methods/close";
import {toggle} from "./methods/toggle"; 

export function Menu(root, toggleBtn, focusables, elemsWithStateSyncedToMenu) 
{
    SubView.call(this, focusables); // extends SubView  

    this.root = root; 
    this.toggleBtn = toggleBtn;  

    // These really shouldn't be part of the class: normally,
    // if we weren't targeting such old browsers, I'd use 
    // a mutation observer for when the menu opened to change
    // these elements that aren't part of the class. 
    // Also note: technically, we can add root and toggleBtn to these but
    // we want to be explicit about what's happening when we open and
    // close the menu. Also, leaving them separate later gives us the opportunity to
    // change the class that is added to these elems--each elem here could be
    // an obj with both the node and the class name to add as props. 
    this.elemsWithStateSyncedToMenu = elemsWithStateSyncedToMenu;

}

Menu.prototype = Object.create(SubView.prototype, {
    constructor: {
        value: Menu,
        enumerable: false,
        writable: true,
        configurable: true,
    },
}); 

Menu.prototype.open = open; 
Menu.prototype.close = close; 
Menu.prototype.toggle = toggle; 

