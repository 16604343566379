import { getElement } from "./helpers/getElement";
import { getElementsToArray } from "./helpers/getElementsToArray"; 
import { animateElemsOnScrollAndResize } from "./ready/animateElementsOnScrollAndResize";
import { autoSmoothScrollToSectionOnLinkClick } from "./ready/autoSmoothScrollToSectionOnLinkClick"; 
import { handleOpeningAndClosingMenus } from "./ready/handleOpeningAndClosingMenus";
import { handleOpeningAndClosingModal } from "./ready/handleOpeningAndClosingModal";
import { initializeComponents } from "./ready/initializeComponents";
import { keepFocusInOpenComponent } from "./ready/keepFocusInOpenComponent"; 
import { fixElementsOnScrollAndResize } from "./ready/fixElementsOnScrollAndResize"
import { validateContactFormOnSubmit } from "./ready/validateContactFormOnSubmit";
import { resetBodyOverflowX } from "./ready/resetBodyOverflowX";

// We're purposely using es5 because the site targets browsers all the 
// way back to IE 10, and the code that webpack and babel 
// produce to target our ancient browsers was just way too bloated (for instance, 
// to get var keyword have to add 4000 lines of code to bundle, if using certain
// modern JS syntax).

// This app obj below can be considered a high level overview of our app.
// It serves as the top level namespace, and reflects a modular es6 app 
// structure. For example, each of ready and components are 
// in their own folder, with each function or class belonging to it 
// having folders and files in it.
var app = {
    page: getElement("[data-page]").getAttribute("data-page"),
    ready: function(callback)
    {
        if (document.readyState !== "loading")
        {
            callback();
        } 
        else 
        {
            document.addEventListener("DOMContentLoaded", callback);
        } 
    },
    components: {mobileMenu: null, modal: null},
    elementsToAnimate: [],
    elementsToFix: [],
    selectAndSortElements: function() 
    {
        this.elementsToFix.push( getElement("header") );
        this.page === "clients" && this.elementsToFix.push( getElementsToArray('.hero, .clients > div') ); // client page backgrounds

        getElementsToArray("[data-sc-animate]").forEach(function(firstElementOfGroup)
        {
            var groupClass = firstElementOfGroup.getAttribute("data-sc-animate");

            // staggers the animation delay for all elements of group sharing the same
            // animation
            var staggerDelay = firstElementOfGroup.getAttribute("data-sc-stagger");

            // delays animation for the whole group (i.e., for all elements the animation 
            // class is not added until this runs out)
            var groupDelay = firstElementOfGroup.getAttribute("data-sc-group-delay"); 

            // Always use parentElement as the scope to prevent cross-group selection
            var parentContainer = firstElementOfGroup.parentElement;

            // Selects all elements in this group within the correct parent scope
            var elementArray = parentContainer.getElementsByClassName(groupClass); 

            this.elementsToAnimate.push({
                elementArray: elementArray,
                staggerDelay: staggerDelay ? parseInt(staggerDelay) : undefined,
                groupDelay: groupDelay ? parseInt(groupDelay) : undefined,
            });
        }, this);

        // handles elements belonging to a group without a direct common parent 
        // (e.g., elements across different sections)
        getElementsToArray("[data-sc-select-group]").forEach(function(groupParent)
        {
            // each data-sc-select-group is made up of at least one valid css selector,
            // and an optional stagger and groupDelay. The attribute can have multiple 
            // selectors, to select multiple groups, so to set the stagger and groupDelay 
            // for each group we have to follow a convention. Each data-sc-select-group
            // takes the following format: 
            // data-sc-select-group="<someValidCssSelector> <;> <stagger in ms> <;> <group-delay in ms> <;> <anotherValidCssSelectorForADifferentGroup>"
            // This attribute value must have at least one selector; the rest 
            // (stagger, group-delays, other group selectors, etc) are optional. Check src ejs for examples.
            var groupInfo = groupParent.getAttribute("data-sc-select-group");
            groupInfo = groupInfo.split(";").map(val => val.trim());

            while (groupInfo.length > 0) 
            {
                // have to use querySelectorAll wrapper here and not get...ByClassName because
                // it's a selector and not just a class name (although it could be a .className)
                var elementArray = getElementsToArray(groupInfo[0], groupParent);
                var staggerDelay = groupInfo[1] ? parseInt(groupInfo[1]) : undefined;
                var groupDelay = groupInfo[2] ? parseInt(groupInfo[2]) : undefined;

                this.elementsToAnimate.push( {
                    elementArray: elementArray,
                    staggerDelay: staggerDelay,
                    groupDelay: groupDelay
                }); 

                groupInfo = groupInfo.slice(3, groupInfo.length); // because we may have more than one selector per data-sc-select-group
            }

        }, this);
    }
};

app.ready(app.selectAndSortElements.bind(app)); 

if (app.page === "home")
{
    app.ready(resetBodyOverflowX);
    app.ready(validateContactFormOnSubmit); 
}

app.ready(function()
{
    initializeComponents(app.components); 
    handleOpeningAndClosingMenus(app.components.mobileMenu);
    app.page === "clients" && handleOpeningAndClosingModal(app.components.modal); 
    keepFocusInOpenComponent(app.components);
});

app.page != "vendors" && app.ready(autoSmoothScrollToSectionOnLinkClick); 

app.ready(function()
{
    animateElemsOnScrollAndResize(app.elementsToAnimate); 
    fixElementsOnScrollAndResize(app.elementsToFix); 
});

// Only uncomment the export below if you want to 
// see the app object something in the browser window.
// You also must uncomment the part in the 
// webpack.config under output.library.
// See webpack config for details.

// export {app};
