export function open() 
{
    this.root.classList.add('open'); 
    this.toggleBtn.classList.add('open'); 
    this.toggleBtn.setAttribute("aria-expanded", "true");

    this.elemsWithStateSyncedToMenu.forEach(function(elem)
    {
        elem.classList.add("open"); 
    });

    this.isOpen = true; 
}