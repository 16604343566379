export function displayWithContent(contentId)
{
    var modalItem = this.modalItems[contentId]; 
    modalItem.content.addClass('open');

    modalItem.isActive = true; 
    this.activeModalItem = modalItem; 
    this.focusables = modalItem.focusables; 

    modalItem.focusables[1].focus(); // focus on first doc/link

    this.dialog.setAttribute('aria-labelledby', contentId); 
};