import { checkPositionAndAnimate } from "../../helpers/checkPositionAndAnimate";

export function animateElemsOnScrollAndResize(elementsToAnimate) 
{ 
    function animateElements()
    {
        elementsToAnimate.forEach(function(elementGroup)
        {
            checkPositionAndAnimate(elementGroup.elementArray, elementGroup.staggerDelay, elementGroup.groupDelay); 
        });
    }
    
    animateElements(); // initial animations on dcl
    window.addEventListener('scroll', animateElements); 
    window.addEventListener('resize', animateElements); 
}