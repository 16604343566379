// the below function no longer has any VISUAL effect (background-attachment still gets set to fixed though).
// It used to work, but current versions of certain broswers will not show the correct visual effect if a 
// transform is set on it (even if nothing technically gets transformed, e.g., translateY(0)); 
// Whether this gets fixed in the future or not, or this is just now the standard behavior, 
// is unknown. We're keeping the function in case it does get fixed or we can attempt a workaround 
// in the future. 

export function fixBackgrounds(clientBackgrounds)
{
    var hero = clientBackgrounds[0]; // first elem is always hero background
    // in stylesheet for clients page, zIndex is set to 0 for hero using @supports, which is only for ios
    var ios = getComputedStyle(hero).zIndex == -1 ? false : true; // have to use double equals here, not triple. Reason being is that in ie zIndex is returned as num while on chrome or firefox it's a string 

    clientBackgrounds.forEach(function(background)
    {
        var coords = background.getBoundingClientRect();

        //have to put background === hero below because the hero section starts with an animation where it translates from below, so starting coords.top position will always be 50 or so
        if( ( coords.top <= 0 || background === hero ) && !ios ) //for safari mobile compatibility for background attachment fixed issues. Technically, this will target ie and firefox too. 
        {
            background.style.backgroundAttachment = 'fixed';
        }
        else 
        {
            background.style.backgroundAttachment = 'scroll';
        }
    });
}