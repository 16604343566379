export function checkPositionAndAnimate(elems, stagger, groupDelay) 
{

    var windowHeight = window.innerHeight;
    var groupIsVisible = false; 
    var coords = []; 

    for ( var i = 0; i < elems.length; i++)
    {
        coords[i] = elems[i].getBoundingClientRect(); 
    }

    // only reason we use named iife here 
    // is so we can call it again in setTimeout. 
    // If we didn't use iife, we'd have to call checkPosAndAnimate 
    // again which would calculate pos again. Doing this just saves
    // the calculation -- at the tradeoff of elems having animation
    // added when user can't see them. 
    (function addAnimation()
    {
        for ( var i = 0, delay = 0; i < elems.length; i++, delay = stagger * i ) 
        {
            if ( ( elems[i].className.indexOf('animate') === -1 ) && ( (coords[i].top < windowHeight) && (coords[i].bottom > 0) ) )
            { 
                if (groupDelay > 0)
                {
                    groupIsVisible = true; 
                    break; 
                }
    
                if (stagger)
                {
                    elems[i].style.animationDelay = delay + "ms"; 
                }
                
                elems[i].className += ' animate'; 
            }
        }    
    
        if (groupDelay > 0 && groupIsVisible)
        {
            setTimeout(addAnimation, groupDelay); 
            groupDelay = 0; 
        }

    })();
};