export function addMenuToFocusables(menuId)
{
    var menu = this.menus[menuId]; 

    if (menuId === 0)
    {
        this.focusables = menu.focusables;
    }
    else 
    {
        // both the root of the composite menu, this.menus[0],
        // and each submenu have the same toggle btn as a 
        // focusable element. We want to include all the submenu's 
        // focusables except for that one. 
        var subMenuToggleBtn = menu.focusables[0]; 
        var indexOfCompositesSubToggle = this.focusables.indexOf(subMenuToggleBtn); 

        this.focusables = 
            this.focusables
            .slice(0, indexOfCompositesSubToggle)
            .concat(menu.focusables)
            .concat(
                this.focusables
                .slice(indexOfCompositesSubToggle + 1, this.focusables.length)
            );
    }
}