export function fixHeader(header)
{
    var coords = header.getBoundingClientRect();
    var elemHeight = coords.bottom - coords.top;    
    
    if( coords.bottom <= -30 ) // if header has been scrolled so bottom is out of view (-30 is small adjustment to make sure element way out of view), make header fixed to page
    {
        header.style.position = 'fixed';
    }
    else if ( (coords.top + window.pageYOffset) <= elemHeight + 5 ) //if bottom of original header position is in view, fix it back to top of page (+5 is small adjustment to make sure animation isn't so rigid)
    {
        header.style.position = 'absolute';
    }
}