export function removeMenuFromFocusables(menuId)
{
    var menu = this.menus[menuId]; 

    var subMenuToggleBtn = menu.focusables[0];
    var indexOfCompositesSubToggle = this.focusables.indexOf(subMenuToggleBtn); 

    this.focusables = 
        this.focusables
        .slice(0, indexOfCompositesSubToggle + 1)
        .concat(
            this.focusables
            .slice(indexOfCompositesSubToggle + menu.focusables.length, this.focusables.length)
        );
}