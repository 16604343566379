import {getElement} from "../getElement"; 

export function validateInputs(e) 
{
    var answer = getElement('#answer', e.target).value;
    answer = Number(answer);
    var verification = getElement('.verification', e.target);
    verification.classList.remove('error');
    var submit = getElement('#submit', e.target);
    submit.disabled = true; 
    
    var isValid= (answer === 13 ? true : false);
    
    if (!isValid) 
    {
        e.preventDefault(); 
        verification.classList.add('error');
        verification.innerHTML = '';
        verification.innerHTML = '<em>Wrong answer! Please enter the right answer to the math problem.</em>';
        submit.disabled = false; 
        submit.value = 'Send Message';
    }
    else
    {
        submit.value = 'Sending..';
    }
    
    return isValid;
}