import {getElement} from "../../helpers/getElement"; 

export function handleOpeningAndClosingMenus(mobileMenu)
{
    function toggleMenu(e)
    {
        var menuId = Number( e.currentTarget.dataset["menuToggle"] ); 
        mobileMenu.toggle(menuId);
    }

    // need below because we only
    // want one sub-menu open at a time 
    function closeOtherOpenSubMenus(e)
    {
        var toggledMenuId = Number( e.currentTarget.dataset["menuToggle"] ); 
        
        // start at 1 because subMenus start at 1 and we 
        // only want to close those.  
        for (var i = 1; i < mobileMenu.menus.length; i++)
        {
            if (i !== toggledMenuId && mobileMenu.menus[i].isOpen)
            {
                mobileMenu.close(i); 
            }
        }
    }
    
    mobileMenu.menus.forEach(function(menu)
    {
        menu.toggleBtn.addEventListener('click', closeOtherOpenSubMenus); 
        menu.toggleBtn.addEventListener('click', toggleMenu);
    });

    
    function closeMobileMenuOnClickOutside()
    {
        mobileMenu.close(); 
    }

    // overlay is outside menu and covers 
    // the whole screen.
    getElement('.overlay').addEventListener('click', closeMobileMenuOnClickOutside); 
}